import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
const defaultProps = {
	"color": "--light",
	"padding": "100px 0",
	"sm-padding": "40px 0",
	"position": "relative",
	"background": "linear-gradient(0deg,rgba(25, 30, 34, 0.3) 0%,rgba(25, 30, 34, 0.3) 100%),--color-darkL2 url(https://jake-rembis.com/img/4.jpg) 30% 50%/cover",
	"quarkly-title": "Form-1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"padding": "8px 8px 8px 8px",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline2",
			"children": "Camo Clash Paintball Arena ile İletişime Geçin"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "50%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"sm-padding": "64px 0 0 0",
			"margin": "32px 0 0 0",
			"max-width": "400px",
			"position": "relative",
			"padding": "0 0 0 64px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "48px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn,
			"position": "absolute"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"children": "Konum"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "Kayı, Bilgehan Cd. No:11, 06980 Kahramankazan"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"padding": "0 0 0 64px",
			"sm-padding": "64px 0 0 0",
			"margin": "64px 0 0 0",
			"max-width": "360px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"position": "absolute",
			"size": "48px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Email"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": <Link href="mailto:contact@jake-rembis.com" text-decoration="none" hover-text-decoration="underline" color="--light">
				contact@jake-rembis.com
			</Link>
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"margin": "64px 0 0 0",
			"max-width": "360px",
			"position": "relative"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"left": "0",
			"category": "md",
			"icon": MdPhone,
			"position": "absolute",
			"size": "48px",
			"top": "0"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"as": "h4",
			"margin": "6px 0",
			"children": "Telefon"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "--headline3",
			"children": "+90 532 065 72 27"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Icon {...override("icon")} />
						<Text {...override("text1")} />
						<Text {...override("text2")} />
					</Box>
					<Box {...override("box6")}>
						<Icon {...override("icon1")} />
						<Text {...override("text3")} />
						<Text {...override("text4")} />
					</Box>
					<Box {...override("box7")}>
						<Icon {...override("icon2")} />
						<Text {...override("text5")} />
						<Text {...override("text6")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;